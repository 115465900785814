<template>
  <layout-sub name="culture">
    <div v-if="video">
      <h4 class="mb-3 mb-lg-4 text-center">
        {{ video.title }}
      </h4>
      <base-horizontal-card
        :img="video.thumb"
        :detail="video.content"
        class="p-culture-video"
      />
    </div>
    <div class="mt-3 mt-lg-4 mt-xl-5">
      <base-news-list :list="news" />
    </div>
    <b-carousel
      ref="myCarousel"
      :interval="3000"
      controls
      class="mt-3 mt-lg-4 mt-xl-5"
    >
      <!-- Slides with image only -->
      <b-carousel-slide
        v-for="(el, index) in pics"
        :key="index"
        :img-src="el.thumb"
      >
        <template #img>
          <div
            class="p-culture-img"
            :style="{ backgroundImage: `url('${el.thumb}')` }"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseHorizontalCard from "@/components/BaseHorizontalCard";
import BaseNewsList from "@/components/BaseNewsList";
import { fatchCulture } from "@/api";

export default {
  name: "Culture",
  components: { LayoutSub, BaseHorizontalCard, BaseNewsList },
  data() {
    return {
      list: [],
      video: null,
      pics: [],
      news: [],
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCulture();
      console.log(data);
      if (data) {
        const { vedio, pic, news } = data;
        if (vedio) this.video = vedio;
        if (pic) this.pics = pic;
        if (news) this.news = news;
      }
    },
  },
};
</script>

<style lang="scss">
.p-culture {
  h4 {
    line-height: 1.5;
  }

  &-video {
    box-shadow: 5px 5px #f2f2f2;

    .c-horizontal-card-img {
      background-color: #000;
      text-align: center;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .card-body {
      padding-top: 20px !important;
    }
  }

  &-img {
    height: 250px;
    background-color: $light;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }

  @include media-breakpoint-up(sm) {
    &-video {
      .c-horizontal-card-img {
        height: 300px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-video {
      .c-horizontal-card-img {
        height: 300px;
        margin-top: 20px;
        margin-left: 20px;

        img {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -150px;
        left: -100px;
        width: 450px;
        height: 450px;
        background: url("../../assets/icon-movie.png") no-repeat;
        background-size: 100% 100%;
        opacity: 0.3;
      }
    }

    &-img {
      height: 450px;
    }
  }

  @include media-breakpoint-up(xl) {
    &-video {
      .c-horizontal-card-img {
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
